const Resume = () => {
    return(
    <section id="resume" className="resume">
      <div className="container">

        <div className="section-title">
          <h2>Resume</h2>
          <p></p>
        </div>

        <div className="row">
          <div className="col-lg-6" data-aos="fade-up">
            <h3 className="resume-title">Education</h3>
            <div className="resume-item">
              <h4>Full Stack Development </h4>
              <h5>05/2022 - 07/2022 </h5>
              <p><em>Zuri Training</em></p>
              <ul>
                <li>HTML (Semantics), CSS3, Modern JavaScript</li>
                <li>Python (Django, Django Rest Framework)</li>
              </ul>
            </div>
            <div className="resume-item">
              <h4>Complete Python Developer </h4>
              <h5>12/2020 - 06/2021</h5>
              <p><em>Zero To Mastery</em></p>
              <ul>
                <li>Python Fundamentals</li>
                <li>Advance Python Programming</li>
                <li>Python (Flask and Flask-Restful)</li>
                <li>Scripting with Python</li>
              </ul>
            </div>

            <div className="resume-item">
              <h4>Mobile Web Specialist</h4>
              <h5>01/2019 - 12/2019</h5>
              <p><em>Google African Developer Scholarship (GADS)</em></p>
              <ul>
                <li>HTML, CSS3, JavaScript</li>
                <li>Angular</li>
              </ul>
            </div>

            <div className="resume-item">
              <h4>Information Technology</h4>
              <h5>01/2018 - 06/2018</h5>
              <p><em>Delta State Vocational Center, Sapele, Delta state</em></p>
              <ul>
                <li>Computer Fundamentals</li>
                <li>Basic Networking</li>
                <li>Microsoft Office Packages</li>
                <li>Basic Web Development</li>
              </ul>
            </div>
          </div>
          <div className="col-lg-6" data-aos="fade-up" data-aos-delay="100">
            <h3 className="resume-title">Professional Experience</h3>
            <div className="resume-item">
              <h4>Full Stack Developer</h4>
              <h5>07/2022 - 09/2022</h5>
              <p><em>Zuri Team, Remote </em></p>
              <ul>
                <li>Led a team of 22 members consisting of <strong> 12 Product Designers</strong> and <strong>10 Web
                    Developers</strong> (Both Frontend and Backend) in the design,
                  development, and implementation of the graphic, layout,frontend and backend(RESTAPI) of a Favicon
                  Generator called
                  <a rel="noreferrer" target="_blank" href="https://zuri-training.github.io/Favicon-Gen-Team-69/">Faviconify</a>.

                </li>
                <li>Set up the Github Team Project Repository and other features (Github Projects e.tc) for effective
                  collaboration</li>
              </ul>
            </div>

            <div className="resume-item">
              <h4>Full Stack Developer</h4>
              <h5>01/2022 - 02/2022</h5>
              <p><em>The Maxibot, Remote </em></p>
              <ul>
                <li>Brainstorm on a strategy for the crypto trading bot</li>
                <li>Designed and Implemented the Frontend Application (Flask) and Backend RestAPI (Flask-Restful)</li>
                <li>Hosted this applications on oracle cloud Ubuntu Instance using Nginx</li>
                <li>Worked with the Binance and Coinbase Pro APIs</li>
              </ul>
            </div>
            <div className="resume-item">
              <h4>Technical Team Leader</h4>
              <h5>02/2020 - 09/2020</h5>
              <p><em>Build4SD (Facebook and Andela), Remote</em></p>
              <ul>
                <li>Led a team of 10 Web developers</li>
                <li>Set up the Github Team Project Repo and other features (Github Projects e.tc) for effective
                  collaboration</li>
                <li> Organized brain storming sessions on the Problem Statement and produced an Algorithm to solve it
                </li>
                <li>Built A Transport web app called Fasta</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
    )
}
export default Resume