import "./Main.css";
import Skills from "./common/Skills";
import Services from "./common/Services";
import Resume from "./common/Resume";
import Portfolio from "./common/Portfolio";
import Contact from "./common/Contact";
import About from "./common/About";
import Hero from "./common/Hero";

const Main = () => {
  return (
    <main id="main">
      <Hero />
      <About />
      <Skills />
      <Services />
      <Portfolio />
      <Resume />
      <Contact />
    </main>
  );
};

export default Main;
