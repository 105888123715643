const Footer = () => {
    return(
    <footer id="footer">
    <div className="container">
      <div className="credits">
        <p>DanTheCM &copy; 2022</p>
      </div>
    </div>
  </footer>)
}
export default Footer