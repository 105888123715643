const About = () => {
  return ( <section id="about" className="about">
    <div className="container">
      <div className="section-title">
        <h2>About</h2>
        <p>
          Hi <br /> I am Etchie Daniel Oritsewehinmi (CM) a self-educated Full
          Stack Developer (ReactJs &amp; Django/Flask)
        </p>
      </div>

      <div className="row">
        <div className="col-lg-4" data-aos="fade-right">
          <img
            src="https://res.cloudinary.com/danthecm/image/upload/v1666536165/Portfolio/profile-img_sler0q.jpg"
            className="img-fluid"
            alt=""
          />
        </div>
        <div className="col-lg-8 pt-4 pt-lg-0 content" data-aos="fade-left">
          <h3>Full Stack Developer &amp; Computer Repair Technician.</h3>
          <p className="fst-italic">Building solutions for efficiency.</p>
          <div className="row">
            <div className="col-lg-6">
              <ul>
                <li>
                  <i className="bi bi-chevron-right"></i>
                  <strong>Email:</strong>
                  <span>etchiedaniel1@gmail.com</span>
                </li>
                <li>
                  <i></i> <strong></strong>
                </li>
                <li>
                  <i className="bi bi-chevron-right"></i>
                  <strong>Phone:</strong> <span>+234 708 666 9479</span>
                </li>
              </ul>
            </div>
            <div className="col-lg-6">
              <ul>
                <li>
                  <i className="bi bi-chevron-right"></i>
                  <strong>Degree:</strong> <span>NABTEB</span>
                </li>
                <li>
                  <i></i> <strong></strong>
                </li>
                <li>
                  <i className="bi bi-chevron-right"></i>
                  <strong>Github:</strong>
                  <span>
                    <a
                      href="https://github.com/danthecm"
                      target="_blank"
                      rel="noreferrer"
                    >
                      @danthecm
                    </a>
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <p>
            I am a Programming Enthusiasts with over 4 Years experience in
            Python(Flask-Restful, Django Rest Framework) and Javascript(ReactJs)
            Programming Languages. As a Developer, my duty is to write clean,
            maintainable and well structured code. As a professional, I value
            time and aim to provide quality work to clients worldwide.
          </p>

          <p>
            As an independent and self-motivated individual, I would like to use
            my skills and experience as well as my passion for technology to
            solve problems for small and large tech companies while working with
            other professionals to improve my skills.
          </p>
        </div>
      </div>
    </div>
  </section>)
};
export default About;
