import "./Header.css";
import { useEffect, useRef } from "react";
import Typed from "typed.js";

const Header = () => {
  const typedRef = useRef();

  useEffect(() => {
    const typed = typedRef.current;
    if (typed) {
      let typed_strings = typed.getAttribute("data-typed-items");
      typed_strings = typed_strings.split(",");

      new Typed(".typed", {
        strings: typed_strings,
        loop: true,
        typeSpeed: 100,
        backDelay: 1000,
        backSpeed: 100,
        showCursor: true,
        autoInsertCss: true,
      });
    }
  }, [typedRef]);

  return (
    <>
      <header id="header">
        <i className="bi bi-list mobile-nav-toggle d-xl-none"></i>
        <div className="d-flex flex-column">
          <div className="profile">
            <img
              src="https://res.cloudinary.com/danthecm/image/upload/v1666536164/Portfolio/profile-round_qozhgj.jpg"
              alt="daniel"
              className="img-fluid rounded-circle"
            />
            <h1 className="text-light">
              <a href="index.html">Daniel Etchie</a>
            </h1>
            <div className="social-links mt-3 text-center">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://linkedin.com/in/danthecm/"
                className="linkedin"
              >
                <i className="bx bxl-linkedin"></i>
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://github.com/danthecm"
                className="github"
              >
                <i className="bx bxl-github"></i>
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://twitter.com/danthecm"
                className="twitter"
              >
                <i className="bx bxl-twitter"></i>
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://facebook.com/daniel.etchie.18"
                className="facebook"
              >
                <i className="bx bxl-facebook"></i>
              </a>
            </div>
          </div>

          <nav id="navbar" className="nav-menu navbar">
            <ul>
              <li>
                <a href="#hero" className="nav-link scrollto active">
                  <i className="bx bx-home"></i> <span>Home</span>
                </a>
              </li>
              <li>
                <a href="#about" className="nav-link scrollto">
                  <i className="bx bx-user"></i> <span>About</span>
                </a>
              </li>
              <li>
                <a href="#skills" className="nav-link scrollto">
                  <i className="bx bx-star"></i> <span>Skills</span>
                </a>
              </li>
              <li>
                <a href="#services" className="nav-link scrollto">
                  <i className="bx bx-server"></i> <span>Services</span>
                </a>
              </li>
              <li>
                <a href="#portfolio" className="nav-link scrollto">
                  <i className="bx bx-book-content"></i> <span>Portfolio</span>
                </a>
              </li>
              <li>
                <a href="#resume" className="nav-link scrollto">
                  <i className="bx bx-file-blank"></i> <span>Resume</span>
                </a>
              </li>
              <li>
                <a href="#contact" className="nav-link scrollto">
                  <i className="bx bx-envelope"></i> <span>Contact</span>
                </a>
              </li>
            </ul>
          </nav>
          {/* <!-- .nav-menu --> */}
        </div>
      </header>
      
    </>
  );
};

export default Header;
