const Services = () => {
  return (
    <section id="services" className="services">
      <div className="container">
        <div className="section-title">
          <h2>Services</h2>
          <p>I offer different Professional Services listed below</p>
        </div>

        <div className="row">
          <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up">
            <div className="icon">
              <i className="bi bi-briefcase"></i>
            </div>
            <h4 className="title">
              <a href="https://en.wikipedia.org/wiki/Web_development">Web Development and Rest APIs</a>
            </h4>
            <p className="description">
              I build professional websites, progressive web apps and Rest APIs
              with Lastest Technologies following best practices to write clean
              and maintainable codes
            </p>
          </div>
          <div
            className="col-lg-4 col-md-6 icon-box"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <div className="icon">
              <i className="bi bi-card-checklist"></i>
            </div>
            <h4 className="title">
              <a href="https://en.wikipedia.org/wiki/Computer_repair_technician">Computer Repair Technician</a>
            </h4>
            <p className="description">
              I render professional Technician services including Motherboard
              Troubleshooting, Repairs/Replacements of laptops parts (RAM, FAN,
              PROCESSOR, HDD, etc.)
            </p>
          </div>
          <div
            className="col-lg-4 col-md-6 icon-box"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <div className="icon">
              <i className="bi bi-person-video"></i>
            </div>
            <h4 className="title">
              <a href="https://en.wikipedia.org/wiki/Video_editing">Video Editing</a>
            </h4>
            <p className="description">
              I am an expert in video editing for different events like Adverts,
              Marriages, Birthdays,Tv shows, Comdey, Burials, Documentary,
              Festivals and others.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Services;
