import { useEffect, useRef } from "react";
import Typed from "typed.js";

const Hero = () => {
    const typedRef = useRef();

    useEffect(() => {
        const typed = typedRef.current;
        if (typed) {
          let typed_strings = typed.getAttribute("data-typed-items");
          typed_strings = typed_strings.split(",");
    
          new Typed(".typed", {
            strings: typed_strings,
            loop: true,
            typeSpeed: 100,
            backDelay: 1000,
            backSpeed: 100,
            showCursor: true,
            autoInsertCss: true,
          });
        }
      }, [typedRef]);

  return (
    <section
      id="hero"
      className="d-flex flex-column justify-content-center align-items-center"
    >
      <div className="hero-container" data-aos="fade-in">
        <h1>Daniel Etchie</h1>
        <p>
          I'm a{" "}
          <span
            ref={typedRef}
            className="typed"
            data-typed-items=" Full Stack Developer, Computer Repair Technician, Video Editor"
          ></span>
        </p>
      </div>
    </section>
  );
};

export default Hero;
