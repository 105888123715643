const Portfolio = () => {
  return (
    <section id="portfolio" className="portfolio section-bg">
      <div className="container">
        <div className="section-title">
          <h2>Portfolio</h2>
          <p>Here is a list of my past projects</p>
        </div>

        <div className="row" data-aos="fade-up">
          <div className="col-lg-12 d-flex justify-content-center">
            <ul id="portfolio-flters">
              <li data-filter="*" className="filter-active">
                All
              </li>
              <li data-filter=".filter-web">Web</li>
              {/* <li data-filter=".filter-apis">APIs</li> */}
              <li data-filter=".filter-video">Videos</li>
            </ul>
          </div>
        </div>

        <div
          className="row portfolio-container"
          data-aos="fade-up"
          data-aos-delay="100"
        >
          <div className="col-lg-4 col-md-6 portfolio-item filter-app">
            <div className="portfolio-wrap">
              <img
                src="https://res.cloudinary.com/danthecm/image/upload/v1666536585/Portfolio/portfolio-1_eluqsz.png"
                className="img-fluid"
                alt=""
              />
              <div className="portfolio-links">
                <a
                  href="https://res.cloudinary.com/danthecm/image/upload/v1666536585/Portfolio/portfolio-1_eluqsz.png"
                  data-gallery="portfolioGallery"
                  className="portfolio-lightbox"
                  title="More Details"
                  data-description="A simple landing page design"
                >
                  <i className="bx bx-plus"></i>
                </a>
                <a
                  rel="noreferrer"
                  href="https://danthecm.github.io/Huddle-Landing-Page/"
                  title="Live Link"
                  target="_blank"
                >
                  <i className="bx bx-link"></i>
                </a>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 portfolio-item filter-web">
            <div className="portfolio-wrap">
              <img
                src="https://res.cloudinary.com/danthecm/image/upload/v1666536585/Portfolio/portfolio-2_qprzmi.png"
                className="img-fluid"
                alt=""
              />
              <div className="portfolio-links">
                <a
                  href="https://res.cloudinary.com/danthecm/image/upload/v1666536585/Portfolio/portfolio-2_qprzmi.png"
                  data-gallery="portfolioGallery"
                  className="portfolio-lightbox"
                  title="Registration Page"
                  data-description="A Simple Registeration page with Basic Validations Javascript Validations"
                >
                  <i className="bx bx-plus"></i>
                </a>
                <a
                  rel="noreferrer"
                  href="https://danthecm.github.io/Zuri-Reg-Page/"
                  title="Live Link"
                  target="_blank"
                >
                  <i className="bx bx-link"></i>
                </a>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 portfolio-item filter-web">
            <div className="portfolio-wrap">
              <img
                src="https://res.cloudinary.com/danthecm/image/upload/v1679169167/Screenshot_from_2023-03-18_20-42-25_alfw4r.png"
                className="img-fluid"
                alt=""
              />
              <div className="portfolio-links">
                <a
                  href="https://res.cloudinary.com/danthecm/image/upload/v1679169167/Screenshot_from_2023-03-18_20-42-25_alfw4r.png"
                  data-gallery="portfolioGallery"
                  className="portfolio-lightbox"
                  title="Fitness App"
                  data-description="An Exercise Fitness App built with ReactJS, MaterialUI and RapidAPI"
                >
                  <i className="bx bx-plus"></i>
                </a>
                <a
                  rel="noreferrer"
                  href="https://cm-fitness-app.vercel.app/"
                  title="Live Link"
                  target="_blank"
                >
                  <i className="bx bx-link"></i>
                </a>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 portfolio-item filter-video">
            <div className="portfolio-wrap">
              <img
                src="https://res.cloudinary.com/danthecm/image/upload/v1666594215/Portfolio/video-marketing-pics_p0znl4.jpg"
                className="img-fluid"
                alt=""
              />
              <div className="portfolio-links">
                <a
                  href="https://res.cloudinary.com/danthecm/video/upload/v1666565070/Portfolio/Maximize_Opportunities_Community_Trust_Network_gz5596.mp4"
                  data-gallery="portfolioGallery"
                  className="portfolio-lightbox"
                  title="Preview"
                  data-description="A Short Advert Video for Community Trust Network"
                  style={{ width: "100%" }}
                >
                  <i className="bx bx-play-circle"></i>
                </a>
                {/* <a
                  rel="noreferrer"
                  href="https://res.cloudinary.com/danthecm/image/upload/v1666565329/Portfolio/study_abroad_2_zbviq4.jpg"
                  title="Live Link"
                  target="_blank"
                >
                  <i className="bx bx-link"></i>
                </a> */}
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 portfolio-item filter-web">
            <div className="portfolio-wrap">
              <img
                src="https://res.cloudinary.com/danthecm/image/upload/v1666607395/Portfolio/retink-presale_page_u4quoj.png"
                className="img-fluid"
                alt=""
              />
              <div className="portfolio-links">
                <a
                  href="https://res.cloudinary.com/danthecm/image/upload/v1666607395/Portfolio/retink-presale_page_u4quoj.png"
                  data-gallery="portfolioGallery"
                  className="portfolio-lightbox"
                  data-description="A landing page for an AI Content Generation Bot"
                  title="Retink Presale Page"
                >
                  <i className="bx bx-plus"></i>
                </a>
                <a
                  rel="noreferrer"
                  href="https://retink-presale.netlify.app/"
                  title="Live Link"
                  target="_blank"
                >
                  <i className="bx bx-link"></i>
                </a>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 portfolio-item filter-web">
            <div className="portfolio-wrap">
              <img
                src="https://res.cloudinary.com/danthecm/image/upload/v1666607395/Portfolio/cmblog_pics_2_z7gpsg.jpg"
                className="img-fluid"
                alt=""
              />
              <div className="portfolio-links">
                <a
                  href="https://res.cloudinary.com/danthecm/image/upload/v1666607395/Portfolio/cmblog_pics_2_z7gpsg.jpg"
                  data-gallery="portfolioGallery"
                  className="portfolio-lightbox"
                  data-description="A Blog Website built with ReactJs and Django RestFramework"
                  title="Blog Website"
                >
                  <i className="bx bx-plus"></i>
                </a>
                <a href="https://danthecmblog.netlify.app/" title="Live Link">
                  <i className="bx bx-link"></i>
                </a>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 portfolio-item filter-video">
            <div className="portfolio-wrap">
              <img
                src="https://res.cloudinary.com/danthecm/image/upload/v1666594215/Portfolio/video-marketing-pics_p0znl4.jpg"
                className="img-fluid"
                alt=""
              />
              <div className="portfolio-links">
                <a
                  href="https://res.cloudinary.com/danthecm/video/upload/v1666567411/Portfolio/Community_Trust_Networks_zq0jqp.mp4"
                  data-gallery="portfolioGallery"
                  className="portfolio-lightbox"
                  title="Community Trust Network"
                  data-description="Do you plan on studying abroad"
                  style={{ width: "100%" }}
                >
                  <i className="bx bx-play-circle"></i>
                </a>
                {/* <a
                  rel="noreferrer"
                  href="https://res.cloudinary.com/danthecm/image/upload/v1666565329/Portfolio/study_abroad_2_zbviq4.jpg"
                  title="Live Link"
                  target="_blank"
                >
                  <i className="bx bx-link"></i>
                </a> */}
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 portfolio-item filter-web">
            <div className="portfolio-wrap">
              <img
                src="https://res.cloudinary.com/danthecm/image/upload/v1666607775/Portfolio/faviconify_rzsu6i.jpg"
                className="img-fluid"
                alt=""
              />
              <div className="portfolio-links">
                <a
                  rel="noreferrer"
                  href="https://res.cloudinary.com/danthecm/image/upload/v1666607775/Portfolio/faviconify_rzsu6i.jpg"
                  data-gallery="portfolioGallery"
                  className="portfolio-lightbox"
                  title="Faviconfiy Favicon Generator"
                >
                  <i className="bx bx-plus"></i>
                </a>
                <a
                  rel="noreferrer"
                  href="https://zuri-training.github.io/Favicon-Gen-Team-69/"
                  title="Live Link"
                  target="_blank"
                >
                  <i className="bx bx-link"></i>
                </a>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 portfolio-item filter-video">
            <div className="portfolio-wrap">
              <img
                src="https://res.cloudinary.com/danthecm/image/upload/v1666594215/Portfolio/video-marketing-pics_p0znl4.jpg"
                className="img-fluid"
                alt=""
              />
              <div className="portfolio-links">
                <a
                  href="https://res.cloudinary.com/danthecm/video/upload/v1666567374/Portfolio/Successful_Application_Steps_acyeuy.mp4"
                  data-gallery="portfolioGallery"
                  className="portfolio-lightbox"
                  title="Community Trust Network"
                  data-description="Do you plan on studying abroad"
                  style={{ width: "100%" }}
                >
                  <i className="bx bx-play-circle"></i>
                </a>
                {/* <a
                  rel="noreferrer"
                  href="https://res.cloudinary.com/danthecm/image/upload/v1666565329/Portfolio/study_abroad_2_zbviq4.jpg"
                  title="Live Link"
                  target="_blank"
                >
                  <i className="bx bx-link"></i>
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Portfolio;
